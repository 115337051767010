import { isFeatureAvailable } from 'cadenza/features';
import { createHotkey } from 'cadenza/hotkeys/hotkeys';

/**
 * @readonly
 * @enum {string}
 */
export const HOTKEYS = {
  GENERAL_CLOSE_MODAL: 'GENERAL_CLOSE_MODAL',
  GENERAL_SUBMIT: 'GENERAL_SUBMIT',
  WORKBOOK_TOGGLE_ANALYSIS_CONTEXT: 'WORKBOOK_TOGGLE_ANALYSIS_CONTEXT',
  WORKBOOK_TOGGLE_VIEW_SETTINGS: 'WORKBOOK_TOGGLE_VIEW_SETTINGS',
  WORKBOOK_TOGGLE_DATA_BROWSER: 'WORKBOOK_TOGGLE_DATA_BROWSER',
  WORKBOOK_UPDATE_ON_DEMAND_VIEW: 'WORKBOOK_UPDATE_ON_DEMAND_VIEW',

  SELECTORS_UPDATE_ON_DEMAND_VIEW: 'SELECTORS_UPDATE_ON_DEMAND_VIEW',

  TIMELINE_3D_CAMERA_ROTATE_UP: 'TIMELINE_3D_CAMERA_ROTATE_UP',
  TIMELINE_3D_CAMERA_ROTATE_DOWN: 'TIMELINE_3D_CAMERA_ROTATE_DOWN',
  TIMELINE_3D_CAMERA_ROTATE_LEFT: 'TIMELINE_3D_CAMERA_ROTATE_LEFT',
  TIMELINE_3D_CAMERA_ROTATE_RIGHT: 'TIMELINE_3D_CAMERA_ROTATE_RIGHT',
  TIMELINE_3D_CAMERA_PAN_UP: 'TIMELINE_3D_CAMERA_PAN_UP',
  TIMELINE_3D_CAMERA_PAN_DOWN: 'TIMELINE_3D_CAMERA_PAN_DOWN',
  TIMELINE_3D_CAMERA_PAN_LEFT: 'TIMELINE_3D_CAMERA_PAN_LEFT',
  TIMELINE_3D_CAMERA_PAN_RIGHT: 'TIMELINE_3D_CAMERA_PAN_RIGHT',
  TIMELINE_3D_CAMERA_ZOOM_IN: 'TIMELINE_3D_CAMERA_ZOOM_IN',
  TIMELINE_3D_CAMERA_ZOOM_OUT: 'TIMELINE_3D_CAMERA_ZOOM_OUT',
  TIMELINE_3D_VIEW_FROM_SOUTH: 'TIMELINE_3D_VIEW_FROM_SOUTH',
  TIMELINE_3D_VIEW_FROM_NORTH: 'TIMELINE_3D_VIEW_FROM_NORTH',
  TIMELINE_3D_VIEW_FROM_EAST: 'TIMELINE_3D_VIEW_FROM_EAST',
  TIMELINE_3D_VIEW_FROM_WEST: 'TIMELINE_3D_VIEW_FROM_WEST',
  TIMELINE_3D_VIEW_FROM_TOP: 'TIMELINE_3D_VIEW_FROM_TOP',
  TIMELINE_3D_RESET_CAMERA_POSITION: 'TIMELINE_3D_RESET_CAMERA_POSITION',
  TIMELINE_3D_TOGGLE_FLOATING_MAP: 'TIMELINE_3D_TOGGLE_FLOATING_MAP',
  TIMELINE_3D_TOGGLE_BOX: 'TIMELINE_3D_TOGGLE_BOX',

  ROUTING_CLOSE: 'ROUTING_CLOSE',
  ROUTING_ADD_WAYPOINT: 'ROUTING_ADD_WAYPOINT',
  ROUTING_REMOVE_WAYPOINT: 'ROUTING_REMOVE_WAYPOINT',

  NAVIGATOR_OPEN_INFO: 'NAVIGATOR_OPEN_INFO',
  NAVIGATOR_OPEN_NODE_MENU: 'NAVIGATOR_OPEN_NODE_MENU',

  SHAPE_LIST_NAV_PREVIOUS: 'SHAPE_LIST_NAV_PREVIOUS',
  SHAPE_LIST_NAV_NEXT: 'SHAPE_LIST_NAV_NEXT',
  SHAPE_LIST_NAV_CLOSE: 'SHAPE_LIST_NAV_CLOSE',
  SHAPE_LIST_NAV_EXPAND: 'SHAPE_LIST_NAV_EXPAND',
  SHAPE_LIST_NAV_FIRST: 'SHAPE_LIST_NAV_FIRST',
  SHAPE_LIST_NAV_LAST: 'SHAPE_LIST_NAV_LAST'

} as const;

export type HotkeyId = keyof typeof HOTKEYS;

// General hotkeys (those are not bound using the hotkey mechanism, but added here so they are shown in the keymap)
createHotkey(
  {
    id: HOTKEYS.GENERAL_CLOSE_MODAL,
    groupId: 'general',
    keyCombos: 'esc'
  }
);

createHotkey(
  {
    id: HOTKEYS.GENERAL_SUBMIT,
    groupId: 'general',
    keyCombos: 'ctrl+enter'
  }
);

// Workbooks

if (isFeatureAvailable('WORKBOOK')) {
  createHotkey(
    {
      id: HOTKEYS.WORKBOOK_TOGGLE_ANALYSIS_CONTEXT,
      groupId: 'workbooks',
      keyCombos: 'alt+shift+1'
    }
  );

  createHotkey(
    {
      id: HOTKEYS.WORKBOOK_TOGGLE_VIEW_SETTINGS,
      groupId: 'workbooks',
      keyCombos: 'alt+shift+2'
    }
  );

  createHotkey(
    {
      id: HOTKEYS.WORKBOOK_TOGGLE_DATA_BROWSER,
      groupId: 'workbooks',
      keyCombos: 'alt+shift+3'
    }
  );

  createHotkey(
    {
      id: HOTKEYS.WORKBOOK_UPDATE_ON_DEMAND_VIEW,
      groupId: 'workbooks',
      keyCombos: 'ctrl+enter'
    }
  );
}

// Selectors

createHotkey(
  {
    id: HOTKEYS.SELECTORS_UPDATE_ON_DEMAND_VIEW,
    groupId: 'selectors',
    keyCombos: 'ctrl+Enter'
  }
);

// Timeline 3D

if (isFeatureAvailable('TIMELINE_3D')) {
  createHotkey(
    {
      id: HOTKEYS.TIMELINE_3D_CAMERA_ROTATE_UP,
      groupId: 'timeline3d',
      keyCombos: 'up'
    }
  );

  createHotkey(
    {
      id: HOTKEYS.TIMELINE_3D_CAMERA_ROTATE_DOWN,
      groupId: 'timeline3d',
      keyCombos: 'down'
    }
  );

  createHotkey(
    {
      id: HOTKEYS.TIMELINE_3D_CAMERA_ROTATE_LEFT,
      groupId: 'timeline3d',
      keyCombos: 'left'
    }
  );

  createHotkey(
    {
      id: HOTKEYS.TIMELINE_3D_CAMERA_ROTATE_RIGHT,
      groupId: 'timeline3d',
      keyCombos: 'right'
    }
  );

  createHotkey(
    {
      id: HOTKEYS.TIMELINE_3D_CAMERA_PAN_UP,
      groupId: 'timeline3d',
      keyCombos: [
        'ctrl+up',
        { keys: 'command+up', os: 'MACOS' }
      ]
    }
  );

  createHotkey(
    {
      id: HOTKEYS.TIMELINE_3D_CAMERA_PAN_DOWN,
      groupId: 'timeline3d',
      keyCombos: [
        'ctrl+down',
        { keys: 'command+down', os: 'MACOS' }
      ]
    }
  );

  createHotkey(
    {
      id: HOTKEYS.TIMELINE_3D_CAMERA_PAN_LEFT,
      groupId: 'timeline3d',
      keyCombos: [
        'ctrl+left',
        { keys: 'command+left', os: 'MACOS' }
      ]
    }
  );

  createHotkey(
    {
      id: HOTKEYS.TIMELINE_3D_CAMERA_PAN_RIGHT,
      groupId: 'timeline3d',
      keyCombos: [
        'ctrl+right',
        { keys: 'command+right', os: 'MACOS' }
      ]
    }
  );

  createHotkey({
    id: HOTKEYS.TIMELINE_3D_CAMERA_ZOOM_IN,
    groupId: 'timeline3d',
    keyCombos: [ 'plus', 'pageup', 'shift+up' ]
  });

  createHotkey({
    id: HOTKEYS.TIMELINE_3D_CAMERA_ZOOM_OUT,
    groupId: 'timeline3d',
    keyCombos: [ '-', 'pagedown', 'shift+down' ]
  });

  createHotkey({
    id: HOTKEYS.TIMELINE_3D_VIEW_FROM_SOUTH,
    groupId: 'timeline3d',
    keyCombos: 's'
  });

  createHotkey({
    id: HOTKEYS.TIMELINE_3D_VIEW_FROM_NORTH,
    groupId: 'timeline3d',
    keyCombos: 'n'
  });

  createHotkey({
    id: HOTKEYS.TIMELINE_3D_VIEW_FROM_EAST,
    groupId: 'timeline3d',
    keyCombos: 'e'
  });

  createHotkey({
    id: HOTKEYS.TIMELINE_3D_VIEW_FROM_WEST,
    groupId: 'timeline3d',
    keyCombos: 'w'
  });

  createHotkey({
    id: HOTKEYS.TIMELINE_3D_VIEW_FROM_TOP,
    groupId: 'timeline3d',
    keyCombos: 't'
  });

  createHotkey({
    id: HOTKEYS.TIMELINE_3D_RESET_CAMERA_POSITION,
    groupId: 'timeline3d',
    keyCombos: 'f'
  });

  createHotkey({
    id: HOTKEYS.TIMELINE_3D_TOGGLE_FLOATING_MAP,
    groupId: 'timeline3d',
    keyCombos: 'm'
  });

  createHotkey({
    id: HOTKEYS.TIMELINE_3D_TOGGLE_BOX,
    groupId: 'timeline3d',
    keyCombos: 'b'
  });
}

// Routing

if (isFeatureAvailable('ROUTING')) {
  createHotkey({
    id: HOTKEYS.ROUTING_CLOSE,
    groupId: 'routing',
    keyCombos: 'shift+esc'
  });

  createHotkey({
    id: HOTKEYS.ROUTING_ADD_WAYPOINT,
    groupId: 'routing',
    keyCombos: [
      { keys: [ 'alt+plus', 'alt+=' ], lang: 'en' }, // on the US and UK keyboard layouts, the '+' is the secondary sign of the '=' key but there's also the numpad plus key
      'alt+plus'
    ]
  });

  createHotkey({
    id: HOTKEYS.ROUTING_REMOVE_WAYPOINT,
    groupId: 'routing',
    keyCombos: 'alt+-'
  });
}

// Navigator

createHotkey({
  id: HOTKEYS.NAVIGATOR_OPEN_INFO,
  groupId: 'navigator',
  keyCombos: 'i'
});

createHotkey({
  id: HOTKEYS.NAVIGATOR_OPEN_NODE_MENU,
  groupId: 'navigator',
  keyCombos: 'a'
});

// Shape list navigation
createHotkey({
  id: HOTKEYS.SHAPE_LIST_NAV_PREVIOUS,
  groupId: 'shapeList',
  keyCombos: 'up',
  showInKeymap: false
});

createHotkey({
  id: HOTKEYS.SHAPE_LIST_NAV_NEXT,
  groupId: 'shapeList',
  keyCombos: 'down',
  showInKeymap: false
});

createHotkey({
  id: HOTKEYS.SHAPE_LIST_NAV_CLOSE,
  groupId: 'shapeList',
  keyCombos: 'left',
  showInKeymap: false
});

createHotkey({
  id: HOTKEYS.SHAPE_LIST_NAV_EXPAND,
  groupId: 'shapeList',
  keyCombos: 'right',
  showInKeymap: false
});

createHotkey({
  id: HOTKEYS.SHAPE_LIST_NAV_FIRST,
  groupId: 'shapeList',
  keyCombos: 'home',
  showInKeymap: false
});

createHotkey({
  id: HOTKEYS.SHAPE_LIST_NAV_LAST,
  groupId: 'shapeList',
  keyCombos: 'end',
  showInKeymap: false
});

