import type { FormGroup } from 'ui/form-group/form-group';
import type { Tabs } from 'ui/tabs/tabs';

import { determineOs, OS } from 'cadenza/utils/determine-os';
import type { EventKey } from 'cadenza/utils/event-util';
import { on, unByKey } from 'cadenza/utils/event-util';
import { uniqueId } from 'cadenza/utils/unique-id';
import { scrollIntoView } from 'cadenza/utils/scroll-into-view';

// We cannot change this selector to `:is(:not([type]), [type=submit])` to support buttons without
// a "type" attribute, because jsdom does not support it and thus our tests would break.
const SUBMIT_BUTTON_SELECTOR = 'button[type=submit]:not([disabled])';

const INVALID_ELEMENT_SELECTOR = '*:invalid, .is-invalid';

export class FormValidation extends HTMLElement {

  _eventKeys: EventKey[] = [];

  connectedCallback () {
    this.hidden = true;

    const form = this.form;

    if (!form) {
      return;
    }

    // Disable auto-completion on any form input unless autocomplete attribute is already set
    // Login fields will still be auto-completed: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#the_autocomplete_attribute_and_login_fields
    if (!form.hasAttribute('autocomplete')) {
      form.autocomplete = 'off';
    }

    // Enforce UTF-8 as charset to not allow the browser to choose charset and possibly perform a
    // conversion to different charset.
    form.acceptCharset = 'UTF-8';

    // Give the form a unique id to identify it via CSS selectors when validating stacked forms
    if (!form.id) {
      form.id = uniqueId();
    }

    const submitButtonSelector =
      `form#${form.id} ${SUBMIT_BUTTON_SELECTOR}:not([form]),${SUBMIT_BUTTON_SELECTOR}[form='${form.id}']`;

    this._eventKeys = [
      on(form, [ 'input', 'change' ], (event) => {
        form.classList.remove('was-validated');
        const group = (event.target as Element).closest('form-group');
        if (group != null) {
          (group as FormGroup).resetInvalid();
        }
      }),

      /*
       * Check validity of native form elements and custom <form-group> elements inside the form. It is
       * necessary to manually check on <form-group> elements as the Constraint Validation API does not
       * care about custom elements.
       * Native elements (those that support Constraint validation API) would block the submission on an
       * invalid state anyway, but we better validate here centralized before even causing a form
       * submission.
       * When the ElementInternals interface is well supported we can use the native validation as well.
       *
       * @see https://developer.mozilla.org/en-US/docs/Web/API/ElementInternals
       * @see https://jsfiddle.net/pm734g2v/ (In case multiple submit buttons are there)
       */
      on(document, 'click', (event) => {
        const nativeElementsValid = form.checkValidity();
        const customElementsValid = checkValidityOnAllFormGroups(form);

        /*
         * If one is invalid we block the following form submission which is called whenever the
         * form is submitted.
         * We call this here after calling checkValidity() of the form and <form-group> elements to
         * allow all elements to update their validity state first.
         */
        if (!nativeElementsValid || !customElementsValid) {
          event.preventDefault();
          const firstInvalidElement = form.querySelector(INVALID_ELEMENT_SELECTOR);

          if (firstInvalidElement) {
            scrollIntoView(firstInvalidElement);
          }
        }

        form.classList.add('was-validated');
      },
      // Restrict to the form with the same id as the submit button is linked to.
      // This is necessary because there could be child forms with their own validation.
      // Accept the form submission if the button is not linked to a form, anyway.
      { delegate: submitButtonSelector }),

      /*
       * Usually, forms are submitted when the user presses Enter. This does not work if a control
       * within the form consumes the event, for example to create a new line in a textarea.
       * To allow the user to submit the form using the keyboard even in such a case, we adopt
       * IntelliJ's behavior and also submit using Ctrl+Enter (or Cmd+Enter on Mac). The submit is
       * triggered by clicking the submit button, which is basically the same for Enter submit.
       */
      on(form, 'keydown', (event: KeyboardEvent) => {
        if (event.key === 'Enter' && (event.ctrlKey || (determineOs() === OS.MACOS && event.metaKey))) {
          const submitButton = form.querySelector(submitButtonSelector) as HTMLButtonElement;
          if (submitButton) {
            submitButton.click();
          }
        }
      })
    ];

    if (!form.matches('[method], [action]')) {
      // If the form does not look like it should be submitted
      // (because it has no "method" or "action" attribute),
      // we prevent that default behavior.
      this._eventKeys.push(on(form, 'submit', (event) => event.preventDefault()));
    }
  }

  disconnectedCallback () {
    unByKey(this._eventKeys);
    this._eventKeys = [];
  }

  get form (): HTMLFormElement | null {
    return this.closest('form');
  }

  checkValidity () {
    const form = this.form;
    if (!form) {
      return true;
    }
    const nativeElementsValid = form.checkValidity();
    const customElementsValid = checkValidityOnAllFormGroups(form);
    return nativeElementsValid && customElementsValid;
  }

}

/**
 * Calls checkValidity() on all <form-group> elements and return true if all of them are determined
 * valid.
 *
 * @param form - The form to check all <form-group> elements of
 * @return True if all elements are determined valid, else false
 */
function checkValidityOnAllFormGroups (form: HTMLFormElement) {
  const formGroups = [ ...form.querySelectorAll('form-group:not(.is-disabled):not([hidden])') ];
  const isValid = formGroups
    // Explicitly call checkValidity() on all form-group elements first to update their validity state
    .map(formGroup => (formGroup as FormGroup).checkValidity())
    // Return true if all are valid, else false
    .every(Boolean);

  // ensure that non-selected tabs visualize errors
  const tabsLists = [ ...form.querySelectorAll('.d-tabs') ] as Tabs[];
  tabsLists.forEach(tabs => tabs.checkAllTabsValidity());

  return isValid;
}

customElements.define('form-validation', FormValidation);
