import h from 'hyperscript';
import memoize from 'lodash-es/memoize';

import { parseSvg } from 'cadenza/utils/svg';

const SIZES = [ 'xxs', 'xs', 's', 'm', 'l', 'xl' ] as const;
const VARIANTS = [ 'light', 'normal', 'bold' ] as const;

export type IconSize = typeof SIZES[number];
export type IconVariant = typeof VARIANTS[number];

/**
 * An element (usually an `SVGSVGElement`) with the `.d-icon` class
 */
export type Icon = Element & { style: CSSStyleDeclaration };

export interface IconOptions {
  /** ARIA label and title; without a label, the icon will be `aria-hidden` */
  label?: string;
  /** One or more CSS class names (space-separated) to add to the icon */
  styleClass?: string;
  /** The size of the icon (default: 'm') */
  size?: IconSize;
  /** An icon variant (default: 'bold') */
  variant?: IconVariant;
}

/**
 * Creates an icon based on the given SVG markup.
 *
 * @param svgMarkup - SVG markup, typically imported from a SVG file, or an SVG element
 * @param [options] - The options parameter can either be a simple string,
 *   in which case it denotes the ARIA label to apply to the created element, or an options object.
 * @return The new Icon
 */
export function icon (svgMarkup: string | Icon, options?: string | IconOptions): Icon {
  const svgElement = ((typeof svgMarkup === 'string') ? memoizedParseSvg(svgMarkup, 'img') : svgMarkup)
    .cloneNode(true) as Icon;

  // In #IE11, SVGs are (interactively) focusable by default.
  // https://stackoverflow.com/a/18646112
  svgElement.setAttribute('focusable', 'false');

  return applyOptions(svgElement, options);
}

/**
 * Creates an icon based on the given url.
 *
 * @param srcUrl - The URL which points to an icon, e.g. an SVG
 * @param [options] - The options parameter can either be a simple string,
 *   in which case it denotes the ARIA label to apply to the created element, or an options object.
 * @return The new Icon
 */
export function cssBasedIcon (srcUrl: string, options?: string | IconOptions): Icon {
  const iconElement = h('.d-icon.css-based-icon', {
    attrs: { role: 'image' }
  });
  iconElement.style.setProperty('--mask-image', `url('${srcUrl}')`);
  return applyOptions(iconElement, options);
}

/**
 * Creates an empty "icon" as a placeholder element
 *
 * @return The empty icon element
 */
export function emptyIcon (): HTMLElement {
  return h('span.d-icon');
}

interface StackIcon {
  /** The SVG markup */
  icon: string;
  /**
   * An array of strings, for predefined classes that can be applied to stacked icons.
   *
   * The currently implemented styles are:
   * - 'error' : changes the color of the icon to red
   * - 'badge' : displays the icon smaller and at the bottom right corner
   */
  styles: ('error' | 'badge')[];
}

/**
 * Creates a span element containing stacked svg element icons.
 *
 * The icons are added in the order they are received, so the icons towards the end of the array
 * will be displayed on top of the ones before.
 *
 * @param icons - icons to add to the stack
 * @param options - additional options
 * @return an element containing stacked svg element icons.
 */
export function iconStack (icons: StackIcon[], options: string | IconOptions) {
  const element = h('.d-icon-stack', { attrs: { role: 'img' } }, icons.map(stackIcon));
  return applyOptions(element, options, 'd-icon-stack');
}

function stackIcon ({ icon: svgMarkup, styles = [] }: StackIcon) {
  const styleClass = styles.map(svgMarkupStyle => `d-icon-${svgMarkupStyle}`).join(' ');
  return icon(svgMarkup, { styleClass });
}

const memoizedParseSvg = memoize(parseSvg); // Caches the result if parameters are the same

function applyOptions (el: Icon, options: string | IconOptions = {}, baseStyleClass = 'd-icon') {
  if (typeof options === 'string') {
    options = { label: options };
  }

  const { size, variant, styleClass, label } = options;

  el.classList.add(baseStyleClass);

  applyIconSize(el, size);
  applyIconVariant(el, variant);

  if (styleClass) {
    el.setAttribute('class', el.getAttribute('class') + ' ' + styleClass);
  }

  if (label) {
    el.setAttribute('aria-label', label);

    if (el instanceof SVGSVGElement) {
      const title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
      title.textContent = label;
      el.prepend(title);
    } else if (el instanceof HTMLElement) {
      el.title = label;
    }
  } else {
    el.setAttribute('aria-hidden', 'true');
  }

  return el;
}

export function applyIconSize (el: Icon, size?: IconSize) {
  for (const s of SIZES) {
    el.classList.toggle(`d-icon-${s}`, s === size && size !== 'm');
  }
}

export function applyIconVariant (el: Icon, variant: IconVariant = 'bold') {
  for (const v of VARIANTS) {
    el.classList.toggle(`d-icon-${v}`, v === variant && variant !== 'normal');
  }
}
