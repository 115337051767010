import Mousetrap, { type MousetrapInstance, type MousetrapStatic } from 'mousetrap';

import { assert, assertNonNullable } from 'cadenza/utils/custom-error';
import { getCurrentLanguage } from 'cadenza/utils/i18n/i18n';
import { getLogger } from 'cadenza/utils/logging';
import { array } from 'cadenza/utils/array-util';
import type { HotkeyId } from 'cadenza/hotkeys/hotkey-definitions';

import i18n from './hotkeys.properties';

const logger = getLogger('hotkeys');

const HOTKEYS_INTERNAL: { [hotkeyId: string]: HotkeyDefinition } = {};
const BOUND_HOTKEYS: { [hotkeyId: string]: MousetrapStatic | MousetrapInstance } = {};

/**
 * Definition used to create a hotkey with the createHotkey() function.
 */
interface UserDefinedHotkey {
  /**
   * A unique identifier that will be used to bind the hotkey to an action, and to show the hotkey in the keymap.
   * There should be a matching entry in the i18n bundle.
   */
  id: HotkeyId;
  /** ID of the group. Hotkeys are displayed grouped in the keymap. */
  groupId: string;
  /** The key combination(s) fo this hotkey */
  keyCombos: UserDefinedKeyCombo;
  /**
   * Should the hotkey be included in the keymap UI. Default is true.
   * Set to false if you want to use the key binding mechanism but don't want the keys to appear in the keymap.
   */
  showInKeymap?: boolean;
}

/**
 * Internal representation of a hotkey.
 */
export interface HotkeyDefinition {
  /** Identifier. */
  id: HotkeyId;
  /** The actual key combo in use, depending on the current platform and user language. */
  keyCombo: NormalizedKeyCombo;
  /** Representation of the key combination used to display in a human-readable form */
  humanReadableKeyCombo: HumanReadableKeys[];
  /** Metadata: group name and description of the hotkey, used in the keymap */
  meta?: { description: string; group: string };
}

/** A key combination to be used in the definition of a hotkey */
interface KeyCombo {
  /** The OS, for which this key combo should be used */
  os?: string;
  /** The language, for which this key combo should be used */
  lang?: string;
  /** One or more key combos to use */
  keys: string | string[];
}

interface NormalizedKeyCombo extends KeyCombo {
  keys: string[];
}

/** A key combo. A string or string array is treated like an object with only the 'keys'  property. */
type UserDefinedKeyCombo = string | KeyCombo | (string | KeyCombo)[];

/** Single key combination in structured human-readable form */
interface HumanReadableKeys {
  key: string;
  modifiers: string[];
}

interface HotkeyBinding {
  hotkeyId: HotkeyId;
  callback: () => unknown;
  scope?: Element;
}

/**
 * Bind a hotkey to execute the given callback. Optionally restricting to a given context.
 *
 * Usage:
 * Hotkeys should be defined inside here, and then referenced by their ID from the code where they
 * should be enabled.
 *
 * This is so that all existing hotkeys are known at all times, and can be displayed in the global keymap.
 *
 * To add a hotkey:
 * - In this module:
 *   - Add an ID in the HOTKEYS enum
 *   - Add a definition using the createHotkey() function
 *   - Add the description in the i18n properties files (hotkeys.properties)
 *   - If it is a new group add the group description in the i18n properties files
 * - In the client code, e.g. in a web component:
 *   - Bind the hotkey using its ID and provide the callback, using the bindHotkey() function
 *
 * @param options
 * @param options.hotkeyId - ID of the pre-defined hotkey, see the HOTKEYS enum
 * @param options.callback - to be executed when the hotkey is pressed
 * @param [options.scope] - A DOM element to which to restrict the hotkeys. If specified, the callback will only be executed if the focus is on the element or on an element contained within it.
 */
export function bindHotkey ({ hotkeyId, callback, scope }: HotkeyBinding) {
  if (BOUND_HOTKEYS[hotkeyId]) {
    throw new Error(
      `Hotkey with ID "${hotkeyId}" is already bound. It should be unbound before binding it again, or it can cause unexpected behavior and memory leaks.`
    );
  }

  const trap = getTrapForElement(scope);
  trap.bind(getHotkeyDefinition(hotkeyId).keyCombo.keys, callback);

  // add to registered hotkeys list
  BOUND_HOTKEYS[hotkeyId] = trap;
}

/**
 * Unbind a previously bound hotkey.
 *
 * @param hotkeyId - ID of the hotkey to unbind
 */
export function unbindHotkey (hotkeyId: string) {
  const trap = BOUND_HOTKEYS[hotkeyId];
  if (trap) {
    trap.unbind(HOTKEYS_INTERNAL[hotkeyId].keyCombo.keys);
    delete BOUND_HOTKEYS[hotkeyId];
  }
}
/**
 * Unbind several previously bound hotkey at once.
 *
 * @param hotkeyIds - IDs of the hotkeys to unbind
 */
export function unbindHotkeys (hotkeyIds: string[]) {
  hotkeyIds.forEach(unbindHotkey);
}

// for tests only
export function __unbindAllHotkeys__ () {
  Object.keys(BOUND_HOTKEYS).forEach(hotkeyId => unbindHotkey(hotkeyId));
}

/**
 * Creates a hotkey definition.
 *
 * This function should be used from the hotkey-definitions.js only (and in unit tests).
 *
 * For many examples of hotkeys definitions, check the bottom of this file, and the unit tests.
 *
 * @param options
 * @param options.id - ID of the hotkey
 * @param options.groupId - ID of the group
 * @param options.keyCombos - Key combo(s)
 * @param options.showInKeymap
 */
export function createHotkey ({ id, groupId, keyCombos, showInKeymap = true }: UserDefinedHotkey) {
  if (HOTKEYS_INTERNAL[id]) {
    throw new Error(`There is already a hotkey registered with id=${id}`);
  }

  const normalizedKeyCombos = normalizeAndSortKeyCombos(keyCombos);
  const activeCombo = determineActiveKeyCombos(normalizedKeyCombos);
  HOTKEYS_INTERNAL[id] = {
    id,
    keyCombo: activeCombo,
    humanReadableKeyCombo: createHumanReadableKeyCombo(activeCombo),
    meta: showInKeymap ? {
      description: i18n(`hotkey.${id}`),
      group: i18n(`group.${groupId}`)
    } : undefined
  };
}

// for tests only
export function __removeHotkeyDefinition__ (hotkeyId: string) {
  delete HOTKEYS_INTERNAL[hotkeyId];
}

interface HotkeysForKeymap  { [groupName: string]: HotkeyForKeymap[] }

export interface HotkeyForKeymap {
  hotkey: HotkeyDefinition;
  active: boolean;
}

export function getHotkeysForKeyMap (): HotkeysForKeymap {
  const keysByGroup: HotkeysForKeymap = {};
  Object.values(HOTKEYS_INTERNAL)
    .forEach(hotkeyDef => {
      if (hotkeyDef.meta) {
        const group = hotkeyDef.meta.group;
        if (!keysByGroup[group]) {
          keysByGroup[group] = [];
        }

        keysByGroup[group].push({
          hotkey: hotkeyDef,
          active: !!BOUND_HOTKEYS[hotkeyDef.id]
        });
      }
    });

  return keysByGroup;
}

/**
 * Get the formatted key combo for a given registered hotkey.
 * This can be used to give a hint about a given hotkey to the user.
 *
 * @param hotkeyId - the ID of the hotkey to display
 * @return - The currently active (based on language and platform) key combo for the given hotkey in a human-readable format
 */
export function getFormattedHotkey (hotkeyId: HotkeyId) {
  return getHotkeyDefinition(hotkeyId).keyCombo.keys.map(formatKeys).join(', ');
}

/**
 * Format a given key combination string to human-readable format.
 *
 * @param keysStr - the key combo to format as a string, e.g. "ctrl+a"
 * @return - The given key combo in a human-readable format e.g. "STRG+A"
 */
export function formatKeys (keysStr: string) {
  const humanReadableKeys = createHumanReadableKeys(keysStr);
  return [ ...humanReadableKeys.modifiers, humanReadableKeys.key ].join('+');
}

function getTrapForElement (element?: Element): MousetrapStatic | MousetrapInstance {
  if (!element) {
    return Mousetrap;
  }

  type HotkeyScope = Element & { dMouseTrap?: MousetrapStatic | MousetrapInstance };
  const hotkeyScope: HotkeyScope = element;

  if (!hotkeyScope.dMouseTrap) {
    hotkeyScope.dMouseTrap = new Mousetrap(hotkeyScope);
  }
  return hotkeyScope.dMouseTrap;
}

function getHotkeyDefinition (hotkeyId: HotkeyId) {
  const hotkeyDefinition = HOTKEYS_INTERNAL[hotkeyId];

  if (!hotkeyDefinition) {
    throw new Error(`There is no hotkey registered with the ID "${hotkeyId}"`);
  }

  return hotkeyDefinition;
}

/**
 * Normalizes the given key combos (single combo or array) to an array of the full object form
 * and prioritize them them based on language and os compatibility
 *
 * @param keyCombos - Candidate combos
 * @return The normalized and prioritized combo objects
 */
function normalizeAndSortKeyCombos (keyCombos: UserDefinedKeyCombo): NormalizedKeyCombo[] {
  logger.debug('unsorted raw combos:', JSON.stringify(keyCombos));
  const os = determineOs(); // we don't store OS and language in a constant because that makes it difficult to mock for tests
  const language = getCurrentLanguage();
  const sortedKeyCombos = normalizeKeyCombos(keyCombos).sort(compareKeyCombo(os, language));

  logger.debug('sorted normalized combos:', JSON.stringify(sortedKeyCombos));

  return sortedKeyCombos;
}

function normalizeKeyCombos (userKeyCombos: UserDefinedKeyCombo): NormalizedKeyCombo[] {
  const keys: string[] = [];
  const keyCombos: KeyCombo[] = [];
  array(userKeyCombos).forEach(stringOrKeyCombo => {
    if (typeof stringOrKeyCombo === 'string') {
      keys.push(stringOrKeyCombo);
    } else {
      keyCombos.push(stringOrKeyCombo);
    }
  });
  if (keys.length) {
    keyCombos.push({ keys }); // put the keys in one combo
  }
  return keyCombos.map(keyCombo => ({ ...keyCombo, keys: array(keyCombo.keys) }));
}

function compareKeyCombo (os: string, language: string) {
  return (a: NormalizedKeyCombo, b: NormalizedKeyCombo) => {
    const weightA = calculateComboWeight(os, language, a);
    const weightB = calculateComboWeight(os, language, b);
    logger.debug(`OS=${os}, lang=${language}, combo A=${JSON.stringify(a)}, combo B=${JSON.stringify(b)}, weight A=${weightA}, weightB=${weightB}`);
    return weightA - weightB;
  };
}

/**
 * Determine the correct key combination(s) for the active locale and OS
 *
 * @param keyCombos - Normalized key combos already prioritized
 * @return The best fitting combo based on the current OS and user language
 */
function determineActiveKeyCombos (keyCombos: NormalizedKeyCombo[]): NormalizedKeyCombo {
  const os = determineOs(); // we don't store OS and language in a constant because that makes it difficult to mock for tests
  const language = getCurrentLanguage();
  const bestFit = keyCombos[keyCombos.length - 1];
  assert(
    (!bestFit.os || bestFit.os === os) && (!bestFit.lang || bestFit.lang === language),
    `No matching key combo found for os=${os} and language=${language}. Key combos: ${JSON.stringify(keyCombos)}`
  );
  return bestFit;
}

/**
 * Prioritize key combos based on language and os compatibility.
 *
 * For the OS and language fields, the following logic is applied:
 * - If the combo's value is defined, and the value equal to the environment's value, 1 is added
 * - If the combo's value is defined, and the value is not equal to the environment's value, 1 is substracted
 * - If the combo's value is undefined, nothing is added
 *
 * @param os
 * @param language
 * @param keyCombo
 * @return Weight of the combo.  Higher is better.
 */
function calculateComboWeight (os: string, language: string, keyCombo: NormalizedKeyCombo): number {
  let weight = 0;

  if (keyCombo.os) {
    weight += (keyCombo.os === os ? 1 : -1);
  }

  if (keyCombo.lang) {
    weight += (keyCombo.lang === language ? 1 : -1);
  }

  return weight;
}

function determineOs () {
  return navigator.platform.startsWith('Mac') ? 'MACOS' : 'default';
}

/**
 * Converts a KeyCombo to structured human readable form
 *
 * @param keyCombo - the key combo
 * @return formatted combos
 */
function createHumanReadableKeyCombo (keyCombo: NormalizedKeyCombo): HumanReadableKeys[] {
  return keyCombo.keys.map(it => createHumanReadableKeys(it));
}

/**
 * Format a single key combination string to human readable form
 *
 * @param keysStr - The keys in a single string e.g. "ctrl+A"
 * @return - Structured and formatted keys object
 */
function createHumanReadableKeys (keysStr: string) {
  const keys = keysStr.split('+').map(it => it.toLowerCase());
  const key = keys.pop();

  validateKey(key);

  return {
    key: humanReadableKey(key!),
    modifiers: keys.map(modifier => {
      validateModifier(modifier);
      return humanReadableKey(modifier);
    })
  };
}

function humanReadableKey (key: string) {
  // Single character: to upper case
  if (key.length === 1) {
    return key.toUpperCase();
  }

  // Try to get translation or just use the code
  return i18n(key, { defaultText: key.toUpperCase() });
}

const KNOWN_MODIFIERS = [ 'ctrl', 'alt', 'shift', 'option', 'command' ];
const KNOWN_SPECIAL_KEYS = [
  'backspace', 'tab', 'enter', 'return', 'capslock', 'esc', 'escape', 'space',
  'up', 'down', 'left', 'right', 'plus',
  'home', 'end', 'pageup', 'pagedown', 'ins', 'del', 'delete',
  'f1', 'f2', 'f3', 'f4', 'f5', 'f6', 'f7', 'f8', 'f9', 'f10', 'f11', 'f12'
];

function validateModifier (modifier: string) {
  assert(
    KNOWN_MODIFIERS.includes(modifier),
    `Invalid modifier specified: "${modifier}". Valid modifiers include: ${KNOWN_MODIFIERS.join(', ')}`
  );
}

function validateKey (key?: string) {
  assertNonNullable(key, 'Key should be specified for all hotkeys');
  assert(
    key.length === 1 || KNOWN_SPECIAL_KEYS.includes(key),
    `Invalid key specified: "${key}". Keys must be either a single character or one of the accepted special keys: ${KNOWN_SPECIAL_KEYS.join(', ')}`
  );
}
