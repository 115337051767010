import dialogPolyfill from 'dialog-polyfill';
import 'dialog-polyfill/dialog-polyfill.css';

import { isEdge } from 'cadenza/utils/browser';
import { focusFirst, lockKeyboardFocus } from 'cadenza/utils/event-util';

import './dialog.css';

/*
 * The HTMLDialogElement type definitions built-into TS are incomplete.
 * https://developer.mozilla.org/en-US/docs/Web/API/HTMLDialogElement
 */
interface HTMLDialogElement extends HTMLElement {
  open: boolean;
  returnValue: string;

  close(): void;
  show(): void;
  showModal(): void;
}

/**
 * @type R the type of the return value
 */
export class Dialog<R = undefined> extends HTMLElement {

  _focusOnCloseElement?: HTMLElement;
  _returnValue?: R;

  protected dialog;

  constructor () {
    super();
    this.dialog = document.createElement('dialog') as HTMLDialogElement;
    this.dialog.addEventListener('cancel', event => this._onCancel(event));
    this.dialog.addEventListener('close', event => this._onClose(event));
    this.dialog.addEventListener('click', event => this._onClick(event));
    this.dialog.addEventListener('keydown', lockKeyboardFocus);
    dialogPolyfill.registerDialog(this.dialog);
  }

  connectedCallback () {
    this.appendChild(this.dialog);
  }

  disconnectedCallback () {
    this._workaroundForBlackPdfInEdge();
  }

  get open () {
    return this.dialog.open;
  }

  set returnValue (value: R | undefined) {
    this._returnValue = value;
    this.dialog.returnValue = '';
  }

  get returnValue (): R {
    // if this.dialog.returnValue has a value, we know that T has been instantiated as string
    return this.dialog.returnValue as unknown as R || this._returnValue!;
  }

  cancel () {
    const cancelEvent = new CustomEvent('cancel', { cancelable: true });
    if (this.dispatchEvent(cancelEvent)) {
      this.close();
    }
  }

  close (returnValue?: R) {
    this.returnValue = returnValue;
    this.dialog.close();
  }

  /**
   * Don't use.
   *
   * @deprecated `remove()` is not actually part of the `Dialog` API. Use {@link #close} or {@link #cancel} instead.
   */
  remove () {
    super.remove();
  }

  show (): Promise<R> {
    this.dialog.show();
    focusFirst(this.dialog);

    return new Promise((resolve) => {
      this.addEventListener('close', () => { resolve(this.returnValue); });
    });
  }

  showModal (): Promise<R> {
    if (document.activeElement instanceof HTMLElement) {
      this._focusOnCloseElement = document.activeElement;
    }

    this.dialog.showModal();
    focusFirst(this.dialog);

    return new Promise((resolve) => {
      this.addEventListener('close', () => { resolve(this.returnValue); });
    });
  }

  _workaroundForBlackPdfInEdge () {
    // Workaround for a bug in Edge that turns the built-in PDF-Viewer all-black after a dialog, e.g. a flyout, opens on
    // top of it.
    // Resizing the <object> tag seems to trigger a re-rendering.
    // Thus, with this workaround the PDF viewer can at least be fixed when the dialog is closed again.
    if (isEdge) {
      const reportObject = document.querySelector('object#reportObject') as HTMLElement;
      if (reportObject) {
        const prevWidth = reportObject.style.width;
        reportObject.style.width = '99.99%';
        setTimeout(() => { reportObject.style.width = prevWidth; });
      }
    }
  }

  _onCancel (event: Event) {
    if (event.target === this.dialog) {
      event.preventDefault();
      this.cancel();
    }
  }

  _onClose (event: Event) {
    if (event.target === this.dialog) {
      this._focusOnCloseElement?.focus();
      this._focusOnCloseElement = undefined;

      this.dispatchEvent(new CustomEvent('close'));
      this.returnValue = undefined; // reset returnValue after listeners consumed it
    }
  }

  _onClick (event: MouseEvent) {
    const clickIsOnCancelButton = (event.target as HTMLElement).closest('.cancel') != null;
    if (clickIsOnCancelButton) {
      this.cancel();
    }
  }

}
